const brandColors = {
  primary: '#13171F',
  secondary: '#E768D9',
};

const sidebarColors = {
  backgroundColor: '#13171F',
  color: '#ffffff',
  dotfileLogo: '#ffffff',
};

const linkColors = {
  color: '#13171F',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
