import {
  AppConfig,
  DocumentAnalysisModelEnum,
  DocumentTypeEnum,
  Environment,
  State,
} from 'shared-domain';

import { environment } from '../environments/environment';
import { countries } from './countries';
import { companyFields } from './company';
import { affiliatedCompanyFields } from './affiliated-company';
import { individualFields } from './individual';
import { stepsConfig } from './step';

export const bootstrapConfig: Partial<Omit<AppConfig, 'environment'>> & {
  environment: Environment;
} = {
  websiteTitle: 'Coruscant',
  countries,
  languages: ['fr', 'en', 'es', 'de', 'it'],
  companyFields,
  affiliatedCompanyFields,
  individualFields,
  stepsConfig,
  environment,
  documentAnalysisConfig: {
    [`document_type:${DocumentTypeEnum.registration_certificate}`]: (
      store: State,
    ) => ({
      automatic_approval: false,
      automatic_rejection: false,
      parameters: {
        model: DocumentAnalysisModelEnum.registration_certificate,
        max_age_in_days: 90,
      },
    }),
  },
};
