export const countries = [
  {
    code: 'DE',
    iso3: 'DEU',
    name: 'Germany',
    localName: 'Deutschland',
    continent: 'Europe',
    emoji: '🇩🇪',
    emojiCode: '1F1E9 1F1EA',
  },
  {
    code: 'AT',
    iso3: 'AUT',
    name: 'Austria',
    localName: 'Österreich',
    continent: 'Europe',
    emoji: '🇦🇹',
    emojiCode: '1F1E6 1F1F9',
  },
  {
    code: 'BE',
    iso3: 'BEL',
    name: 'Belgium',
    localName: 'Belgien',
    continent: 'Europe',
    emoji: '🇧🇪',
    emojiCode: '1F1E7 1F1EA',
  },
  {
    code: 'BG',
    iso3: 'BGR',
    name: 'Bulgaria',
    localName: 'България',
    continent: 'Europe',
    emoji: '🇧🇬',
    emojiCode: '1F1E7 1F1EC',
  },
  {
    code: 'CY',
    iso3: 'CYP',
    name: 'Cyprus',
    localName: 'Κύπρος',
    continent: 'Europe',
    emoji: '🇨🇾',
    emojiCode: '1F1E8 1F1FE',
  },
  {
    code: 'HR',
    iso3: 'HRV',
    name: 'Croatia',
    localName: 'Hrvatska',
    continent: 'Europe',
    emoji: '🇭🇷',
    emojiCode: '1F1ED 1F1F7',
  },
  {
    code: 'DK',
    iso3: 'DNK',
    name: 'Denmark',
    localName: 'Danmark',
    continent: 'Europe',
    emoji: '🇩🇰',
    emojiCode: '1F1E9 1F1F0',
  },
  {
    code: 'ES',
    iso3: 'ESP',
    name: 'Spain',
    localName: 'España',
    continent: 'Europe',
    emoji: '🇪🇸',
    emojiCode: '1F1EA 1F1F8',
  },
  {
    code: 'EE',
    iso3: 'EST',
    name: 'Estonia',
    localName: 'Eesti',
    continent: 'Europe',
    emoji: '🇪🇪',
    emojiCode: '1F1EA 1F1EA',
  },
  {
    code: 'FI',
    iso3: 'FIN',
    name: 'Finland',
    localName: 'Suomi',
    continent: 'Europe',
    emoji: '🇫🇮',
    emojiCode: '1F1EB 1F1EE',
  },
  {
    code: 'FR',
    iso3: 'FRA',
    name: 'France',
    localName: 'France',
    continent: 'Europe',
    emoji: '🇫🇷',
    emojiCode: '1F1EB 1F1F7',
  },
  {
    code: 'GR',
    iso3: 'GRC',
    name: 'Greece',
    localName: 'Ελλάδα',
    continent: 'Europe',
    emoji: '🇬🇷',
    emojiCode: '1F1EC 1F1F7',
  },
  {
    code: 'HU',
    iso3: 'HUN',
    name: 'Hungary',
    localName: 'Magyarország',
    continent: 'Europe',
    emoji: '🇭🇺',
    emojiCode: '1F1ED 1F1FA',
  },
  {
    code: 'IE',
    iso3: 'IRL',
    name: 'Ireland',
    localName: 'Ireland',
    continent: 'Europe',
    emoji: '🇮🇪',
    emojiCode: '1F1EE 1F1EA',
  },
  {
    code: 'IT',
    iso3: 'ITA',
    name: 'Italy',
    localName: 'Italia',
    continent: 'Europe',
    emoji: '🇮🇹',
    emojiCode: '1F1EE 1F1F9',
  },
  {
    code: 'LV',
    iso3: 'LVA',
    name: 'Latvia',
    localName: 'Latvija',
    continent: 'Europe',
    emoji: '🇱🇻',
    emojiCode: '1F1F1 1F1FB',
  },
  {
    code: 'LT',
    iso3: 'LTU',
    name: 'Lithuania',
    localName: 'Lietuva',
    continent: 'Europe',
    emoji: '🇱🇹',
    emojiCode: '1F1F1 1F1F9',
  },
  {
    code: 'LU',
    iso3: 'LUX',
    name: 'Luxembourg',
    localName: 'Luxemburg',
    continent: 'Europe',
    emoji: '🇱🇺',
    emojiCode: '1F1F1 1F1FA',
  },
  {
    code: 'MT',
    iso3: 'MLT',
    name: 'Malta',
    localName: 'Malta',
    continent: 'Europe',
    emoji: '🇲🇹',
    emojiCode: '1F1F2 1F1F9',
  },
  {
    code: 'NL',
    iso3: 'NLD',
    name: 'Netherlands',
    localName: 'Nederland',
    continent: 'Europe',
    emoji: '🇳🇱',
    emojiCode: '1F1F3 1F1F1',
  },
  {
    code: 'PL',
    iso3: 'POL',
    name: 'Poland',
    localName: 'Polska',
    continent: 'Europe',
    emoji: '🇵🇱',
    emojiCode: '1F1F5 1F1F1',
  },
  {
    code: 'PT',
    iso3: 'PRT',
    name: 'Portugal',
    localName: 'Portugal',
    continent: 'Europe',
    emoji: '🇵🇹',
    emojiCode: '1F1F5 1F1F9',
  },
  {
    code: 'CZ',
    iso3: 'CZE',
    name: 'Czechia',
    localName: 'Česko',
    continent: 'Europe',
    emoji: '🇨🇿',
    emojiCode: '1F1E8 1F1FF',
  },
  {
    code: 'RO',
    iso3: 'ROU',
    name: 'Romania',
    localName: 'România',
    continent: 'Europe',
    emoji: '🇷🇴',
    emojiCode: '1F1F7 1F1F4',
  },
  {
    code: 'SK',
    iso3: 'SVK',
    name: 'Slovakia',
    localName: 'Slovensko',
    continent: 'Europe',
    emoji: '🇸🇰',
    emojiCode: '1F1F8 1F1F0',
  },
  {
    code: 'SI',
    iso3: 'SVN',
    name: 'Slovenia',
    localName: 'Slovenija',
    continent: 'Europe',
    emoji: '🇸🇮',
    emojiCode: '1F1F8 1F1EE',
  },
  {
    code: 'SE',
    iso3: 'SWE',
    name: 'Sweden',
    localName: 'Sverige',
    continent: 'Europe',
    emoji: '🇸🇪',
    emojiCode: '1F1F8 1F1EA',
  },
  {
    code: 'IS',
    iso3: 'ISL',
    name: 'Iceland',
    localName: 'Ísland',
    continent: 'Europe',
    emoji: '🇮🇸',
    emojiCode: '1F1EE 1F1F8',
  },
  {
    code: 'LI',
    iso3: 'LIE',
    name: 'Liechtenstein',
    localName: 'Liechtenstein',
    continent: 'Europe',
    emoji: '🇱🇮',
    emojiCode: '1F1F1 1F1EE',
  },
  {
    code: 'NO',
    iso3: 'NOR',
    name: 'Norway',
    localName: 'Noreg',
    continent: 'Europe',
    emoji: '🇳🇴',
    emojiCode: '1F1F3 1F1F4',
  },
];
